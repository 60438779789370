@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

/*basic styling - reset browser default */
*,
*::after,
*::before {
  box-sizing: border-box;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

html {
  font-size: 1rem;
  line-height: 1.1112rem; /*18px*/
  font-weight: 300;
  font-style: normal;
  color:'#0F143C';
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  font-size: inherit;
  font-weight: inherit;
  font-style: normal;
  color:'#0064DC';
}

h2 {
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 0.2rem;
}

p {
  font-size: 0.9rem;
}

span {
  font-size: 0.9rem;
}